<template>
<div>
    <ProjectPageTemplate
        projectTitle="CANVAS BUTTONS"
        :imageSource="require('@/assets/images/projectLinks/buttonsLink.png')"
        shortDescription="NOT SUITABLE FOR TOUCH DISPLAYS AT THIS TIME - A selection of different buttons types written in JavaScript and drawn on an HTML canvas. Clicking the buttons changes the values and these are displayed as numbers or text drawn from sprite sheets."
        longDescription= "I created these buttons for use in a UI for an editor and thought it would be a good demonstration of their uses. The number and text functions allow for values to be input through the parameters to then be draw from parts of a sprite sheet.<br><br>
                        <li>Clicking on the Boolean switch returns a true or false value that then alternates button images and on/off text.<br>
                        <li>Clicking on the up and down buttons changes an integer value which is displayed with numbers. A function allows for min and max values(0 to 10 in this case) and stops changes beyond that.<br>
                        <li>Clicking and dragging on the X and Y sliders moves their position along the specific axis relative to the mouse position. Each has a min and max position that can be set and a value is returned based on the percentage of the position between these points.<br>
                        <li>Clicking on the mode select buttons highlights the selected button and displays the button name in text. The previously selected button is deactivated."
        :importedLinkData="techLinksData"
    >
    <template v-slot:main-content>
        <JsButtons></JsButtons>
    </template>
    </ProjectPageTemplate>
</div>

</template>

<script>
import ProjectPageTemplate from "@/components/ProjectPageTemplate";
import JsButtons from '../components/JsButtonsComp.vue';
import techLinks from '@/data/techLinks.js'
export default {
    name: 'jsButtons',
    components: {
        ProjectPageTemplate,
        JsButtons,
    },
    data() {
        return {
            techLinksData: [
                techLinks.javascript,
            ],
        }
    }
}
</script>

<style lang="scss" scoped>

</style>