function draw() {
    mouseDownCount = mouseDown === true ? mouseDownCount += 1: 0;
    menuCheck();
    createOnSreenList();    
    
    drawOnScreenItems();
    if (!running) {
        return
    }
    requestAnimationFrame(draw);
}

function checkClick(object) {
    if (Math.abs(object.xPos - mousePos.xPos) < object.width) {
        if (Math.abs((object.yPos - (object.height /2)) - mousePos.yPos) < (object.height / 2)) {
            return true;
        }
    }
    return false
}

function buttonAction(item) {
    if (item.clicked === 1) {
        if (item.action === 1) {
            testValue = valueChanging(testValue, item.valueChange, 0, 10)
            refreshCanvas = true;
        }
        if (item.action === 2) {
            mode = boolean(item.valueChange) === 1 ? 'ON' : 'OFF';
            item.valueChange = item.valueChange === 1 ? 0 : 1;
            item.drawing.image = item.frame.baseImage + item.valueChange;
            refreshCanvas = true;
        }
        if (item.action === 5) {
            modeSelect(item);
            refreshCanvas = true;
        }
    }
    if (item.clicked > 0) {
        if (item.action === 3) {
            sliderXpos(item, 230, 80);
            refreshCanvas = true;
        }
        if (item.action === 4) {
            sliderYpos(item, 510, 360);
            refreshCanvas = true;
        }
    }  
}

function boolean(input) {
    return input === 1 ? 0 : 1;
}

function valueChanging(value, change, min, max) {
    let tempValue = value += change;
    if (tempValue < min) return min;
    if (tempValue > max) return max;
    return tempValue;
}

function sliderXpos(item, max, min) {
    let tempXpos = mousePos.xPos;  
    if (tempXpos < min) tempXpos = min;
    if (tempXpos > max) tempXpos = max;
    item.xPos = tempXpos;
    item.drawing.xPos = tempXpos;
    sliderValue.xPos = Math.round(((tempXpos - min) / (max - min)) * 100);
}

function sliderYpos(item, max, min) {
    let tempYpos = mousePos.yPos;  
    if (tempYpos < min) {tempYpos = min}
    if (tempYpos > max) {tempYpos = max}
    item.yPos = tempYpos;
    item.drawing.yPos = tempYpos;
    sliderValue.yPos = Math.round(100 - ((tempYpos - min) / (max - min)) * 100);
}

function modeSelect(input) {
    buttonList.forEach(object => {
       if (object.action === 5) object.drawing.image = object.frame.baseImage;
    })
    editMode = editModesList[input.valueChange];
    input.drawing.image = input.frame.baseImage + 1;
    
}

function menuCheck() {
    buttonList.forEach(function(object) {
        if (!mouseDown) {
            object.clicked = 0;
            return;
        }
        if (checkClick(object)) object.clicked += 1;
        if (object.clicked > 0) buttonAction(object);
    })
}


function completeUrl(urlList) {
    if (urlList === null) return;
    let newUrlList =  urlList.map(URL => require('@/assets/images/jsButtons/' + URL));
    return(newUrlList);
}

function loadImages() {
    let fullImageUrls = [...completeUrl(imageURL)];
    fullImageUrls.forEach(function(src) { 
        const image = new Image();
        image.src = src;
        image.onload = function() {
            imageCount += 1;
            if(imageCount === fullImageUrls.length){ 
                levelAssestLoaded(); 
            }
        }
        images.push(image); 
    });
}

function levelAssestLoaded() {
    loadButtons(buttonStartList);
    requestAnimationFrame(draw); 
}

function addListOnScreen(list){
    list.forEach(function(item) {
        if (item.onScreen === true) {
            onScreenList.push(item);
        }
    })
}

function createOnSreenList() {
    if (refreshCanvas === true) {
        onScreenList = [];
        addListOnScreen(buttonList);
    }
}

function drawOnScreenItems() {
    if (refreshCanvas === true) {
        ctx.clearRect(0, 0, canvasWidth, canvasHeight);
        drawText(15, 10, 'BOOLEAN SWITCH', 0.5, 1);
        drawText(175, 55, mode, 0.5, 2);
        drawText(15, 130, 'UP AND DOWN', 0.5, 1);
        drawNumber(250, 180, testValue, 2);
        drawText(15, 300, 'X AND Y SLIDERS', 0.5, 1)
        drawText(25, 360, 'X VALUE', 0.5, 1)
        drawNumber(175, 350, sliderValue.xPos, 3);
        drawText(25, 410, 'Y VALUE', 0.5, 1)
        drawNumber(175, 400, sliderValue.yPos, 3);
        drawText(15, 550, 'MODE SELECT', 0.5, 1)
        drawText(30, 680, `MODE ${editMode}`, 0.5, 1)
        
        ctx.save();
        onScreenList.forEach(function(item) {
            drawImage(images[item.drawing.image], item.drawing.xPos, item.drawing.yPos, 1, item.drawing.direction, item.drawing.render)
        })
        ctx.restore();
        refreshCanvas = false;
    }
}

function drawImage(image, x, y, scale = 1, direction, render, definedCtx = ctx) {
    definedCtx.setTransform(scale * direction, 0, 0, scale, x, y); 
    definedCtx.drawImage(image, Math.floor(-image.width * render.x), Math.floor(-image.height * render.y));
}


function drawNumber(x, y, value, digits) {
    let xS = 28;
    let yS = 45;
    let stringValue = value.toString();
    let vLength = stringValue.length;
    while (vLength < digits) {
        stringValue = "0" + stringValue;
        vLength = stringValue.length;
    }
    ctx.save();
    ctx.setTransform(1, 0, 0, 1, x, y);
    for (let i = 0; i < digits; i++ ) {
        let num = stringValue[i];
        ctx.drawImage(images[14], (num % 10) * xS, 0, xS, yS, xS * i, 0, xS, yS);
    }
    ctx.restore();
}

function drawText(x, y, text, size = 1, lSpace = 3) {
    let yS = 60; // Height of letter in sprite sheet
    let letterNum = 0; // Number of letter in alphabet
    let wordWidth = 0; //Running count of the pixel width of text
    let letterWidth = 0; // Pixel width of letter
    ctx.save();
    ctx.setTransform(1, 0, 0, 1, x, y);
    for (let i = 0; i < text.length; i++) { // Repeat loop for every letter of string
        letterNum = 0 // Reset number to check next character in string
        while (letters.charAt(letterNum) != text.charAt(i)) { // Repeat until letterNum equals the number of letter in alphabet
            letterNum +=1
        }
        letterWidth = letterWidths[letterNum] * size; // Set letterWidth to letter number in array
        ctx.drawImage(images[15], 0, letterNum * yS, letterWidths[letterNum], yS, wordWidth, 0, letterWidth, yS * size);
        wordWidth += Math.ceil(letterWidth + lSpace); // Add letter width and spacing to wordwidth for x pos of next letter
    }
    ctx.restore();
}

function setUpEvents() {
    canvas.addEventListener('mousemove', onMouseMove, false);
    canvas.addEventListener('mousedown', onMouseDown, false);
    canvas.addEventListener('mouseup', onMouseUp, false);
    canvas.addEventListener('mouseleave', onMouseUp, false);
    // canvas.addEventListener('touchmove', onMouseMove);
    // canvas.addEventListener('touchstart', onMouseDown);
    // canvas.addEventListener('touchend', onMouseUp);
}

function getMousePos(evt) {
    return {
        xPos: evt.offsetX,
        yPos: evt.offsetY,
    };
}

function loadButtons(elist) {
    let tempObjectList = [];
    for (let i = 0; i < elist.length;) {
        tempObjectList.push({
            type: elist[i], 
            xPos: elist[i + 1],
            yPos: elist[i + 2], 
            drawing: {
                image: elist[i + 3]
            } 
        })
        i += 4;
    }
    tempObjectList.forEach(item => buttonList.push(makeButton(item)))

}

function makeButton(input) {
    return {
        onScreen: true,
        clicked: 0,
        typeName: buttonTypeData[input.type].name,
        type: input.type,
        xPos: input.xPos,
        yPos: input.yPos,
        direction: 1,
        valueChange: buttonTypeData[input.type].valueChange,
        action: buttonTypeData[input.type].actionNumber,
        delete: 0,
        width: (buttonTypeData[input.type].width /  2),
        height: (buttonTypeData[input.type].height),
        frame: {
            baseImage: input.drawing.image,
            render: {
                x: [0.5],
                y: [1],
            },
        },
        drawing: {
            image: input.drawing.image,
            xPos: input.xPos,
            yPos: input.yPos,
            direction: 1,
            render: {
                x: 0.5,
                y: 1,
            },
        },
    }
}

function setUp() {
    if (running) {
        console.log('An instance of this programme is already running')
        return;
    }
    running = true;
    refreshCanvas = true;
    buttonList = [];
    images = [];
    canvas = document.getElementById('game-layer');
    ctx = canvas.getContext('2d');
    setUpEvents();
    loadImages();     
}

function cleanUp() {
    running = false;
    imageCount = 0;
    canvas.removeEventListener('mousemove', onMouseMove);
    canvas.removeEventListener('mousedown', onMouseDown);
    canvas.removeEventListener('mouseup', onMouseUp);
    canvas.removeEventListener('mouseleave', onMouseUp);
    // canvas.removeEventListener('touchmove', onMouseMove);
    // canvas.removeEventListener('touchstart', onMouseDown);
    // canvas.removeEventListener('touchend', onMouseUp);
}

let onMouseMove = function(evt) {
    mousePos = getMousePos(evt);
}

let onMouseDown = function() {
    mouseDown = true;
}

let onMouseUp = function() {
    mouseDown = false;
}


let ctx;
let refreshCanvas = true;
let running = false;
const canvasWidth = 320;
const canvasHeight = 720;
let testValue = 0;
let canvas;
let mousePos = {
    xPos: 0,
    yPos: 0,
    width: 10,
    height: 10,
};
let sliderValue = {
    xPos: 0,
    yPos: 0,
}

const imageURL = ["downer.svg", "upper.svg", "switchOff.svg", "switchOn.svg", "xSlider.svg","ySlider.svg", "new.svg", 
"newS.svg", "save.svg", "saveS.svg", "overwrite.svg", "overwriteS.svg", "load.svg", "loadS.svg","numbers.png", 
"letters.png"];
let images = [];
let imageCount = 0;
let mouseDownCount = 0;
let mouseDown = false;
let onScreenList = [];
let buttonList = [];
const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ ';
const letterWidths = [40,36,38,40,35,30,40,37,20,23,36,30,40,36,44,30,43,38,36,37,36,42,54,39,37,34,25];
let buttonStartList = [
    0,100,230,0,
    1,180,230,1,
    2,100,80,2,
    3,130,500,4,
    4,290,500,5,
    5,50,660,6,
    6,120,660,8,
    7,190,660,10,
    8,260,657,12];
const buttonTypeData = [
    {name: 'down', height: 64, width: 64, valueChange: -1, actionNumber: 1},
    {name: 'up', height: 64, width: 64, valueChange: 1, actionNumber: 1},
    {name: 'boolean', height: 22, width: 65, valueChange: 0, actionNumber: 2},
    {name: 'xSlider', height: 45, width: 25, valueChange: 0, actionNumber: 3},
    {name: 'ySlider', height: 25, width: 45, valueChange: 0, actionNumber: 4},
    {name: 'modeRefresh', height: 64, width: 64, valueChange: 0, actionNumber: 5},
    {name: 'modeSave', height: 64, width: 64, valueChange: 1, actionNumber: 5},
    {name: 'modeOverwrite', height: 64, width: 64, valueChange: 2, actionNumber: 5},
    {name: 'modeLoad', height: 64, width: 64, valueChange: 3, actionNumber: 5},

];
let editModesList = ['REFRESH','SAVE','OVERWRITE','LOAD'];
let mode = 'OFF';
let editMode = editModesList[1];

export default setUp;
export {cleanUp};