<template>
    <div id="gamescreen">
      <canvas id="game-layer" width="320" height="720"></canvas>
      
    </div>

</template>

<script>
import jsButtons, {cleanUp} from "../gameFiles/jsButtonsIndex";
export default {
    name: 'JsButtons',
    mounted() {
        jsButtons();
    },
    beforeDestroy() {
        cleanUp();
    }
}
</script>

<style lang="scss" scoped>
   #gamescreen canvas { 
        position: absolute;
        top: 0;
        left: 0;
        width: 320px;
        height: 720px;
        

    }     
    #gamescreen {
        overflow: hidden;
        background-image: linear-gradient(rgb(157, 152, 255), rgb(45, 67, 97));
        position: relative;
        width: 320px;
        height: 720px;
    }
</style>